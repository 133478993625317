exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-club-tsx": () => import("./../../../src/pages/club.tsx" /* webpackChunkName: "component---src-pages-club-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-news-news-tsx": () => import("./../../../src/pages/news/news.tsx" /* webpackChunkName: "component---src-pages-news-news-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-stadium-tsx": () => import("./../../../src/pages/stadium.tsx" /* webpackChunkName: "component---src-pages-stadium-tsx" */),
  "component---src-pages-teams-team-tsx": () => import("./../../../src/pages/teams/team.tsx" /* webpackChunkName: "component---src-pages-teams-team-tsx" */),
  "component---src-pages-teams-tsx": () => import("./../../../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */)
}

